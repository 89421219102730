body {
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.table-header-row {
  font-family: "roboto";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.5%;
  color: white;
  background-color: #19345f;
}

.table-header {
  text-wrap: nowrap;
  padding: 10px;
  padding-left: 25px;
  padding-right: 35px;
}

.table-header:first-child {
  padding-left: 20px;
  padding-right: 100px;
}

td {
  padding: 15px;
  padding-left: 25px;
  padding-right: 20px;
}

tr:nth-child(odd):not(.table-header-row) {
  background-color: #f1f9ff;
}

tr:hover:not(.table-header-row) {
  cursor: pointer;
  background-color: #eeeeee;
}

td:nth-child(1),
td:nth-child(2) {
  color: #3f79e9;
}

.up-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 7px white;
  border-top-width: 0;
  cursor: pointer;
  margin-left: 4px;
  visibility: hidden;
}

.down-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 7px white;
  border-bottom-width: 0;
  margin-top: 1px;
  cursor: pointer;
  margin-left: 4px;
  visibility: hidden;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

th.table-header:hover {
  .up-arrow {
    visibility: visible;
  }
  .down-arrow {
    visibility: visible;
  }
}
table {
  border-collapse: collapse;
}

tr {
  border: 1px solid #dcdcdc;
}

.divider {
  height: 20px;
  border-right: 1px solid #c5c5c5;
  margin-left: 10px;
  margin-right: 10px;
}





.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
}

.styled-table thead th {
  background-color: #e8eef7; /* Light blue for header */
  color: #333;
  font-weight: bold;
  border: 2px solid #d3d9e3; /* Increased border width */
  padding: 10px;
}

.styled-table tbody tr {
  background-color: #ffffff; /* Default body row color */
  border: 2px solid #d3d9e3; /* Cell borders */
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f6f8fc; /* Alternate row color */
}

.styled-table tbody tr:hover {
  background-color: #eef3fc; /* Hover effect */
}

.styled-table td {
  padding: 8px;
  border: 2px solid #d3d9e3; /* Cell borders */
}

.styled-table input,
.styled-table select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
}
