.styled-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;
    

  }
  
  .styled-table thead th {
    background-color: #f4f6fa; /* Light blue for header */
    color: #333;
    font-weight: bold;
    border: 1px solid #d3d9e3; /* Cell border */
    padding: 12px;
    text-align: center; /* Center-align header text */
    vertical-align: middle; /* Center-align header content vertically */
  }
  
  .styled-table tbody tr {
    background-color: #ffffff; /* Default body row color */
    border: 1px solid #d3d9e3; /* Cell borders */
    text-align: center; /* Center-align row content horizontally */
  }
  
  .styled-table tbody tr:nth-child(even) {
    background-color: #f6f8fc; /* Alternate row color */
  }
  
  .styled-table tbody tr:hover {
    background-color: #eef3fc; /* Hover effect */
  }
  
  .styled-table td {
    padding: 10px;
    border: 1px solid #d3d9e3; /* Cell borders */
    vertical-align: middle; /* Center-align cell content vertically */
    text-align: center; /* Center-align cell content horizontally */
  }

  
  .styled-table input,
  .styled-table select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    text-align: center; /* Center-align input text */
  }
  
  .styled-table .input_wrap {
    display: flex;
    justify-content: center;
    text-align: center; /* Center-align header text */
    vertical-align: middle; 
     width:100px,
     
  }
  
  .styled-table .errortext {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  h3 {
    text-align: left; /* Align title to the left */
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  