/**=====================
     17. List CSS Start
==========================**/
.list-group-item.active {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
}
.list-group {
  i {
    margin-right: $list-group-margin;
  }
  img {
    height: 40px;
    width: 40px;
    margin-right: $list-group-margin;
  }
}

.tab-javascript {
  ul {
    &.list-group {
      .list-group-item {
        border-radius: 0;
        border-top: none;
        cursor: pointer;
      }
      &:first-child {
        .list-group-item {
          border-top: 1px solid rgba($black, 0.125);
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      &:last-child {
        .list-group-item {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
/**=====================
     17. List CSS Ends
==========================**/
