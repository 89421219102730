/**=====================
    82. cart CSS start
==========================**/
.cart {
  .total-amount {
    text-align: right !important;
    margin-top: 30px;
  }
  .amount-space {
    margin-left: 40px;
  }
  .cart-btn-transform {
    text-transform: capitalize;
  }
  .qty-box {
    width: 25%;
    margin: 0 auto;
    .input-group {
      .btn {
        background-color: #ddd !important;
        border-color: #ddd !important;
        padding: 8px 12px;
      }
    }
  }
}
.cart-buttons {
  margin-top: 30px;
  .cart-btn {
    float: right;
    padding-right: 85px;
  }
}
/**=====================
    82. cart CSS Ends
==========================**/
