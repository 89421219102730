.pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}

.nav-icon-container {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon-container:hover {
  cursor: pointer;
  background-color: #e5e1e1;
  border-radius: 50%;
}
