.ott-donut-chart>div>div>svg>foreignObject>div>div>.apexcharts-legend-marker {
    margin-right: 10px !important;
    top: -2px !important;
}

.ott-donut-chart>div>div>svg>foreignObject>div>div>.apexcharts-legend-text {
    color: #7C7676 !important;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.ott-donut-chart>div>div>svg>foreignObject>div>div {
    margin: 5px !important;
    margin-bottom: 10px !important;
}