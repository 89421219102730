.confirmation-modal {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 35%;
  width: 400px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.confirmation-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: rgb(248, 80, 80);
}

.modal-header-text {
  font-size: 20px;
  font-weight: 600;
}

.confirmation-modal-content {
  padding: 20px;
}

.confirmation-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.modal-button {
  border-radius: 2px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.confirm {
  background-color: rgb(118, 247, 118);
}

.cancel {
  background-color: rgb(230, 225, 225);
}
